/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {layout} from "../contexts/layout-context"

import Header from "./header"
import Sidebar from "./sidebar"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let layoutClass = [];
  if (layout.sidebarOpened) {
    layoutClass.push("with-sidebar");
  }

  return (
    <>
      <div id="wrapper">
        <div id="main" className={layoutClass.join(" ")}>
          <div className="inner">
            <Header siteTitle={data.site.siteMetadata.title} />
            {children}
            <footer className="align-center">
              <p>Reach me on social media: <br/><a href="https://twitter.com/travelfrogmd" className="fab fa-twitter" style={{border: '0 none', fontSize: '2rem', margin: '10px'}}></a>  <a className="fab fa-linkedin" href="https://www.linkedin.com/in/nicoletanigai/" style={{border: '0 none', fontSize: '2rem', margin: '10px'}}></a></p>
              <p>Nicoleta Nagailic © {new Date().getFullYear()}, Built with <span> ♥ , </span>
              <a href="https://www.gatsbyjs.org">Gatsby</a> & <a href="https://drupal.org">Drupal 8</a>.
              </p>
            </footer>
          </div>
        </div>
        <Sidebar />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
