import { Link } from "gatsby"
import React, { Component } from "react"
import {LayoutContext, layout} from "../contexts/layout-context"
import Search from "./search/index"


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActive: false
    };
  }

  toggleClass = () => {
    this.setState(prevState => ({
      showActive: !prevState.showActive
    }));
    layout.sidebarOpened = !layout.sidebarOpened;
  };

  render() {
    let toggleClass = [];
    if (!this.state.showActive) {
      toggleClass.push("inactive");
    }
    const searchIndices = [
      { name: `Articles`, title: `Articles`, hitComp: `ArticleHit` },
    ]
    return (
      <div id="sidebar" className={toggleClass.join(" ")}>
        <div className="inner">
          <section id="search" className="alt">
            <Search collapse indices={searchIndices} />
          </section>
          <nav id="menu">
            <header className="major">
              <h2>Menu</h2>
            </header>
            <ul>
              <li><Link to="/">Homepage</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/category/projects">Projects</Link></li>
              <li><Link to="/category/book-club">Book Club</Link></li>
              <li><Link to="/category/diary">Diary</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
          <footer id="footer">
            <p className="copyright">&copy; Nicoleta Nagailic. All rights reserved.</p>
          </footer>

        </div>
        <a href="#" className="toggle" onClick={this.toggleClass}>Toggle</a>
      </div>
    )
  }
}
Sidebar.contextType = LayoutContext;

export default Sidebar
